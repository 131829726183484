// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aula-gratuita-redacao-george-orwell-1984-js": () => import("./../src/pages/aula-gratuita-redacao-george-orwell-1984.js" /* webpackChunkName: "component---src-pages-aula-gratuita-redacao-george-orwell-1984-js" */),
  "component---src-pages-base-js": () => import("./../src/pages/base.js" /* webpackChunkName: "component---src-pages-base-js" */),
  "component---src-pages-competicao-cultural-1984-js": () => import("./../src/pages/competicao-cultural-1984.js" /* webpackChunkName: "component---src-pages-competicao-cultural-1984-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-olimpiada-pocket-1984-js": () => import("./../src/pages/olimpiada-pocket-1984.js" /* webpackChunkName: "component---src-pages-olimpiada-pocket-1984-js" */),
  "component---src-pages-olimpiada-pocket-agrobusiness-js": () => import("./../src/pages/olimpiada-pocket-agrobusiness.js" /* webpackChunkName: "component---src-pages-olimpiada-pocket-agrobusiness-js" */),
  "component---src-pages-olimpiada-pocket-coronavirus-js": () => import("./../src/pages/olimpiada-pocket-coronavirus.js" /* webpackChunkName: "component---src-pages-olimpiada-pocket-coronavirus-js" */),
  "component---src-pages-olimpiada-pocket-hackeando-ecossistemas-js": () => import("./../src/pages/olimpiada-pocket-hackeando-ecossistemas.js" /* webpackChunkName: "component---src-pages-olimpiada-pocket-hackeando-ecossistemas-js" */),
  "component---src-pages-olimpiada-pocket-segunda-guerra-js": () => import("./../src/pages/olimpiada-pocket-segunda-guerra.js" /* webpackChunkName: "component---src-pages-olimpiada-pocket-segunda-guerra-js" */),
  "component---src-pages-pocketv-js": () => import("./../src/pages/pocketv.js" /* webpackChunkName: "component---src-pages-pocketv-js" */)
}

